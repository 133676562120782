import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

class PrivacyStatement extends Component {
    render() {
        return (
            <Container className='Container'>
                <h1>ASPO - PRIVACY STATEMENT</h1>
                Updated: 18.04. 2018
                <br /><br />

                We at <b>Aspo</b> (Aspo Oyj (business ID 1547798-7) and its affiliates) (<b>“we”</b> or <b>“us”</b>) believe that protecting the privacy of our customers, potential customers, website users and other business contacts (hereinafter collectively the <b>“Business Partners”</b>) is important to our business and values. In the course of our business operations, we may receive, collect, maintain, use and share personal data of representatives, personnel and employees of our Business Partners (collectively hereinafter <b>“Data Subject(s)”</b> or <b>“User(s)”</b>). We are committed to protecting the privacy of our Business Partners and for that purpose endeavour to ensure that our data processing practices meet the requirements set forth in the applicable data protection laws the General Data Protection Regulation (EU) 2016/679 (the <b>“GDPR”</b>).
                <br /><br />
                The purpose of this Privacy Statement is to demonstrate our robust commitment to the Data Subjects' right to privacy and data protection. This Privacy Statement outlines how we handle information that can be used to directly or indirectly identify a Data Subject (the <b>“Personal Data”</b>) and describes our privacy practices in relation to use of our website(s) and services offered by us (collectively, the <b>“Services”</b>), as well as Data Subjects' choices regarding their rights such as access and rectification of Personal Data. Processing of Personal Data related to recruitment and job applications is governed by a separate privacy policy.
                <br /><br />
                We act as a controller with respect to the information we process in connection with our business relationships, for example, Data Subjects' contact details and other commercial Personal Data.
                <br /><br />

                <h2>1 Processing of Personal Data</h2>

                We process Personal Data of Data Subjects to offer the Services. In this context, Personal Data may be processed for the following purposes:
                (i) management and administration of our relationship with our Business Partners;
                <br /><br />
                (ii) provision of information and materials related to our products and services, for example by newsletters and direct marketing;
                <br /><br />
                (iii) identifying potential customers who are using our Services for the purposes of targeting relevant marketing to such persons;
                <br /><br />
                (iv) registrations for our events and webinars;
                <br /><br />
                (v) market and customer analysis and surveys; and
                <br /><br />
                (vi) contacting the Data Subject and to provide information on the Services.
                <br /><br />
                We process Personal Data on the following basis:
                <br /><br />
                (i) for the purposes of our legitimate interests related to the customer relationship and other relevant relationship between us and the respective Business Partners (Article 6(f) of the GDPR);
                <br /><br />
                (ii) for the purposes of performance of a contract to which the Business Partner or Data Subject is party or in order to take steps at the request of the Data Subject or the Business Partner prior to entering into a contract (Article 6(b) of the GDPR); or
                <br /><br />
                (iii) to comply with legal obligations applicable to us (Article 6(c) of the GDPR, such as corporate and accounting).
                <br /><br />
                We may also ask for Data Subject's consent for certain type of the processing of Personal Data if required by applicable laws. When collecting such consents, we inform the Data Subject of the respective purposes of processing and such processing is conducted only when appropriate consent is received.
                <br /><br />

                <h2>2 Information we may collect and use</h2>

                We may collect the Data Subjects' following Personal Data:
                <br /><br />
                (i) Name and contact information, such as email address, phone number, employer name and address and job title / position;
                <br /><br />
                (ii) Permissions and consents relating to the processing and Data Subject's choices
                <br /><br />
                (iii) Other information necessary for maintaining the Business Partner relationship;
                <br /><br />
                (iv) Personal Data accumulating from the use of our Services, such as information on the pages browsed by the user, the sites the user accessed our websites, the device model, individual device and/or cookie identifier, the channel through which the service is accessed (e.g. if access through a certain social media platform (such as LinkedIn, Facebook, Twitter), browsing session time and duration. Such observed data collected about the use of our online services may be connected to other data we have about the User. The use and collection of information by using cookies is described in more detail in our Cookie Policy; and
                <br /><br />
                (v) data obtained from third party sources related to the Services.
                <br /><br />
                Personal Data processed by us is mainly collected directly from the Data Subject or through our Business Partner having a direct relationship with the Data Subject (such as employment). Online technologies may be used for gathering information about the use of our online services. While the provision of certain Personal Data is necessary for use of our Services (for example contact details of Business Partners' representatives), certain Personal Data is provided voluntarily. Personal Data may be updated and supplemented by collecting data from private and public registers.
                <br /><br />
                We do not collect or process sensitive personal data (Personal Data of special categories).
                <br /><br />

                <h2>3 Disclosure of Personal Data</h2>
                For the purposes stated in this Privacy Statement, Personal Data may be disclosed, when necessary, to authorities, other companies within the same group of companies as us, and to other third parties, such as third party service providers (such as our IT vendors, marketing agencies conducting marketing on our behalf etc.). In such case, the Personal Data will only be disclosed for purposes defined above. We do not sell or otherwise disclose Personal Data to third parties outside Aspo Group for such third parties' own purposes.
                <br /><br />
                Personal Data may also be transferred regularly outside the European Union and the European Economic Area (“EU/EEA”) within Aspo Group and also to our service providers. In case Personal Data is transferred outside EU / EEA, such transfers are either made to a country that is deemed to provide a sufficient level of privacy protection by the European Commission or transfers are carried out by using appropriate safeguards such as standard data protection clauses adopted or otherwise approved by the EU Commission or competent data protection authority in accordance with the GDPR (“Standard Data Protection Clauses”).
                <br /><br />

                <h2>4 Retention Period</h2>
                Personal Data collected in connection with our Services shall be retained as long as need for the purposes defined about, unless such data is replaced through regular updates or otherwise. As regards Personal Data related to our relationship with our Business Partners, we retain Data Subject's Personal Data for at least three (3) years from end of the respective Business Partner relationship or Data Subject's latest contact with us. Personal Data may be, in whole or partly, retained for longer or shorter period if required by applicable law (such customer data for accounting purposes) or if there is some other justified reason for us to retain or delete the Personal Data. In such a case, once the reason to retain the Personal Data ceases to exist, the Data Subject's Personal Data shall be erased without delay.
                <br /><br />
                We evaluate the necessity and accuracy of the Personal Data on a regular basis and endeavour to ensure that the incorrect and unnecessary Personal Data are corrected or deleted.
                <br /><br />

                <h2>5 Data Subjects' Rights</h2>
                Data Subject has a right to request from us:
                (i) access to and rectification or under certain conditions, erasure of Data Subject's Personal Data;
                <br /><br />
                (ii) for restriction of processing concerning the Data Subject or to object to processing (such as processing for the direct marketing purposes);
                <br /><br />
                (iii) to receive, when the automatic processing is based on consent or performance of a contract, Personal Data that has been provided to us by the Data Subject, in a structured, commonly used and machine-readable format and to transmit those data to another controller; and
                <br /><br />
                (iv) The right to opt out of receiving electronic direct marketing communications from us. All electronic direct marketing communications that you may receive from us, such as e-mail messages and SMS-messages, give you an option of not to receive such communications from us in the future.
                <br /><br />
                Data Subject may exercise the aforementioned rights by sending a written request to us. Where the processing is based on a consent, Data Subject has a right to withdraw such consent at any time. Please note that this will not affect the lawfulness of processing based on consent before its withdrawal. It should, however, be noted that in case the processing is based on a consent and such consent is withdrawn we may not be able to provide all the services as were provided pursuant to the consent.
                <br /><br />
                In case the Data Subject considers that its rights under the data protection laws have been infringed, the Data Subject may lodge a complaint with the supervisory authority of the Data Subject's residence in the EU (e.g. in Finland the Finnish Data Protection Ombudsman).
                <br /><br />

                <h2>6 Security Safeguards</h2>
                Securing the integrity and confidentiality of Personal Data is important to us. We have taken adequate technical and organisational measures in order to keep Personal Data safe and to secure it against unauthorized access, loss, misuse or alteration by third parties, such as encryption, access controls, firewalls. Nevertheless, considering the cyber threats in modern day online environment, we cannot give full guarantee that our security measures will prevent illegally and maliciously operating third parties from obtaining access to Personal Data or absolute security of the Personal Data during its transmission or storage on our systems.
                <br /><br />

                <h2>7 Other</h2>
                We may make changes to this Privacy Statement at any time by giving a notice on the website and/or by other applicable means. The Data Subjects are highly recommended to review the Privacy Statement on our website every now and then, referring to the date of the last modification listed at the top of this page. If the Data Subject objects to any of the changes to this Privacy Statement, the Data Subject should cease using the Services, where applicable, and he/she can request that we remove the Personal Data, unless applicable laws require us to retain such Personal Data. Unless stated otherwise, the then-current Privacy Statement applies to all Personal Data we process at the time.
                <br /><br />

                <h2>8 Contact information of data controller</h2>
                Data controller: Aspo Oyj (business ID 1547798-7) and its affiliates
                <br /><br />
                All contacts and inquiries related to this Privacy Statement should be addressed to
                <br /><br />
                <table>
                    <tr><td>Aspo Oyj &nbsp;&nbsp;&nbsp;&nbsp;</td><td>Johanna Suhonen &nbsp;&nbsp;&nbsp;&nbsp;</td> <td>johanna.suhonen@aspo.com &nbsp;&nbsp;&nbsp;&nbsp;</td><td>phone +358 9 5211*</td></tr>
                    <tr><td>ESL Shipping Oy &nbsp;&nbsp;&nbsp;&nbsp;</td><td>Mikko Rausti &nbsp;&nbsp;&nbsp;&nbsp;</td> <td>mikko.rausti@eslshipping.com &nbsp;&nbsp;&nbsp;&nbsp;</td><td>phone +358 9 5211*</td></tr>
                    <tr><td>Kauko Oy &nbsp;&nbsp;&nbsp;&nbsp;</td><td>Johan Enlund &nbsp;&nbsp;&nbsp;&nbsp;</td> <td>johan.enlund@kauko.com &nbsp;&nbsp;&nbsp;&nbsp;</td><td>phone +358 9 5211*</td></tr>
                    <tr><td>Leipurin Oyj &nbsp;&nbsp;&nbsp;&nbsp;</td><td>Lauri Lehto &nbsp;&nbsp;&nbsp;&nbsp;</td> <td>lauri.lehto@leipurin.com  &nbsp;&nbsp;&nbsp;&nbsp;</td><td>phone +358 9 521 70*</td></tr>
                    <tr><td>Telko Oy &nbsp;&nbsp;&nbsp;&nbsp;</td><td>Taina Nikula &nbsp;&nbsp;&nbsp;&nbsp;</td> <td>taina.nikula@aspo.com &nbsp;&nbsp;&nbsp;&nbsp;</td><td>phone +358 9 5211*</td></tr>
                </table>
                <br /><br />
                <br /><br />
            </Container>
        );
    }
}

export default PrivacyStatement;