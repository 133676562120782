import { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import NoAccessMessage from "./NoAccessMessage";
import PreviewBox from "./PreviewBox";
import { UserContext } from './UserContext';

function Welcome({ content }) {

    const { user } = useContext(UserContext)

    function boxInCol(box) {
        return (
            <Col style={{ marginTop: '1em', padding: '0em 1em', maxWidth: '18em'}}>
                <PreviewBox boxContent={box} />
            </Col>
        )
    }

    function renderBox(box) {
        if (box.roleNeeded != null) {
            console.log(box.roleNeeded)
            if (user && user.userRoles.indexOf("type--" + box.roleNeeded) > -1) {
                return boxInCol(box);
            }
        }
        else {
            return boxInCol(box);
        }
    }

    function renderBoxes(boxes) {
        return (
            boxes.map((box) => (
                renderBox(box)
            ))
        )
    }

    return (
        <Container className="Container">
            {user && user.userRoles.length === 3 /* 3 = [anonymous, authenticated, a third needed] */ &&
                <NoAccessMessage />
            }

            <Row style={{ padding: '0em 5em 0em 5em' }}>
                {renderBoxes(content.previewBoxes)}
            </Row>
        </Container>
    );
}

export default Welcome;