import React, { useState, useEffect, useContext } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { Container } from "react-bootstrap";
import { UserContext } from './UserContext';
import NoAccessMessage from './NoAccessMessage';

function PowerBiEmbedded(params) {

    const { user } = useContext(UserContext)

    const reportId = params.reportId;

    const [embeddedDetails, setEmbeddedDetails] = useState({
        token: "",
        url: ""
    });

    useEffect(() => {
        (async function () {
            if (reportId !== null) {
                const { EmbedToken, EmbedUrl } = await (await fetch(`/api/PowerBIEmbeddedToken?reportid=${reportId}`)).json();
                setEmbeddedDetails({
                    token: EmbedToken,
                    url: EmbedUrl
                });
            }
        })();
    }, [reportId]);

    function Forbidden() {
        return <Container className='Container'>
            <NoAccessMessage />
        </Container>
    }

    function Report() {
        return (
            <Container className='Container'>
                <PowerBIEmbed
                    embedConfig={{
                        type: 'report', // Supported types: report, dashboard, tile, visual and qna
                        id: reportId,
                        embedUrl: embeddedDetails.url,
                        accessToken: embeddedDetails.token,
                        tokenType: models.TokenType.Embed,
                        settings: {
                            personalBookmarksEnabled: true,
                            panes: {
                                filters: {
                                    expanded: false,
                                    visible: false
                                }
                            },
                            background: models.BackgroundType.Transparent,
                            // layoutType: models.DisplayOption.FitToPage
                        }
                    }}

                    eventHandlers={
                        new Map([
                            ['loaded', function () { console.log('Report loaded'); }],
                            ['rendered', function () { console.log('Report rendered'); }],
                            ['error', function (event) { console.log(event.detail); }]
                        ])
                    }

                    cssClassName={"Embed-container"}

                    getEmbeddedComponent={(embeddedReport) => {
                        window.report = embeddedReport;
                    }}
                />
            </Container>)
    }

    if (user && user.userRoles.length === 2) /* 2 = [anonymous, authenticated] */ {
        return (<Forbidden />)
    }
    return <Report />
}

export default PowerBiEmbedded;