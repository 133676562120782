function Footer() {

    return (
        <div style={{ position: 'fixed', left: 0, bottom: 0, right: 0, padding: '10px', color: 'white', background: '#212529', fontSize: 'smaller' }}>
            <div>
                © 2022 ESL Shipping

                <a key='privacy' href={'/privacy'}
                    style={{ margin: 40, color: 'white', textDecoration: 'none' }}>
                    Privacy Statement
                </a>
                <a key='privacy' href={'/terms-and-conditions'}
                    style={{ color: 'white', textDecoration: 'none' }}>
                    Terms and conditions
                </a>
                <div>
                    <img
                        style={{ position: 'absolute', right: 10, bottom: 10 }}
                        src='https://www.eslshipping.com/hubfs/images/aspo-company-logo.png' />
                </div>
            </div>
        </div>
    );
}

export default Footer;