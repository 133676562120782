import { useEffect } from 'react';
import { useContext } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { UserContext } from './UserContext';

function NavigationBar({ tabs }) {

    const { user, setUser } = useContext(UserContext);

    useEffect(() => {
        (async () => {
            setUser(await getUserInfo());
        })();
    }, []);

    async function getUserInfo() {
        try {
            const response = await fetch(`/.auth/me`);
            const payload = await response.json();
            const { clientPrincipal } = payload;
            console.log(clientPrincipal)
            return clientPrincipal;
        } catch (error) {
            console.error('No profile could be found', error);
            return undefined;
        }
    }

    return (
        <Navbar collapseOnSelect expand={true} className="NavBar">
            <Container>
                <Navbar.Brand as={Link} to="/">
                    <img height='65px'
                        src="https://www.eslshipping.com/hubfs/images/Gallery/ESL_LA01_vaaka_300dpi.jpg"
                        alt="ESL Shipping" />
                </Navbar.Brand>

                <>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            {user && user.userRoles.findIndex(r => r.includes("type--")) > -1 &&
                                <NavDropdown title="Reports">
                                    {tabs.map((tab) => {
                                        return user.userRoles.indexOf("type--" + tab.roleNeeded) > -1
                                            ? <NavDropdown.Item id="nav-dropdown-item" as={Link} to={tab.path} >{tab.title}</NavDropdown.Item>
                                            : null
                                    })}
                                </NavDropdown>
                            }
                        </Nav>
                        {user &&
                            <Navbar.Text>
                                {user.userDetails} &nbsp; &nbsp;
                                <a href={'/logout'} style={{ color: "#00b605" }}>Logout</a>
                            </Navbar.Text>
                        }
                    </Navbar.Collapse>
                </>
            </Container>
        </Navbar>
    );
}

export default NavigationBar;