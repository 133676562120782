import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PowerBiEmbedded from './Components/PowerBIEmbedded';
import NavigationBar from './Components/NavigationBar';
import Welcome from './Components/Welcome';
import Navigation from './Components/NavigationContent.json';
import PreviewBoxes from './Components/WelcomeContent.json';
import { UserContext } from './Components/UserContext';
import { useMemo, useState } from 'react';
import LoggedOut from './Components/LoggedOut';
import RedirectAfterLogout from './Components/RedirectAfterLogout';
import PrivacyStatement from './PrivacyStatement';
import TermsAndConditions from './TermsAndConditions';
import Footer from './Components/Footer';
import Test from './Components/Test';

function App() {

  const [user, setUser] = useState(null);

  const value = useMemo(() => ({ user, setUser }), [user, setUser]);

  return (
    <Router>
      <UserContext.Provider value={value}>

        <NavigationBar tabs={Navigation.tabs} />

        <Routes>

          {user !== null &&
            <Route exact path='/' element={<Welcome content={PreviewBoxes} />} />
          }
          {user === null &&
            <Route exact path='/' element={<LoggedOut />} />
          }

          {Navigation.tabs.map((tab) => (
            <Route path={tab.path} element={<PowerBiEmbedded reportId={tab.id} />} />
          ))}
          <Route path={"Composite-models-test"} element={<PowerBiEmbedded reportId={"a43f0159-973f-4a9a-bdbf-c32fe8a9f16b"} />} />
          <Route path={"test-report-202303"} element={<PowerBiEmbedded reportId={"7b867609-6b23-4a6b-94cd-0efec25a4294"} />} />
          <Route path={"test-report-202305"} element={<PowerBiEmbedded reportId={"0dd6c292-a6b7-471d-ad5c-52937fc26dad"} />} />

          <Route exact path='/loggedout' element={<RedirectAfterLogout />} />
          <Route exact path='/terms-and-conditions' element={<TermsAndConditions />} />
          <Route exact path='/privacy' element={<PrivacyStatement />} />
          <Route exact path='/ping' element={<Test />} />

        </Routes>

        <Footer />


      </UserContext.Provider>
    </Router>
  );
}

export default App;
