import React, { Component } from 'react';
import { useEffect } from 'react';

function RedirectAfterLogout() {

    useEffect(() => {
        window.location.assign('https://www.eslshipping.com/');
    }, []);

    return (
        <div></div>
    );

}

export default RedirectAfterLogout;