import { useState } from "react";
import { Container, FormCheck, Alert, Col } from "react-bootstrap";

const LoggedOut = () => {

    const [termsApproved, setTermsApproved] = useState(true)

    function areTermsApproved(event) {
        setTermsApproved(event.target.checked);
    }

    return (
        <Container className='Container'>
            Welcome to ESL Shipping's Extranet for customers. <br />
            Please press&nbsp;
            {termsApproved
                ? <a style={{ background: 'none', border: 'none', color: '#00b605', textDecoration: 'underline', cursor: 'pointer' }}
                    key='login-back' onClick={() => window.location.href = '/login?post_login_redirect_uri=/'}>Log in</a>
                : <a style={{ background: 'none', border: 'none', color: 'black', textDecoration: 'none', cursor: 'not-allowed' }}
                    key='login-back' >Log in</a>
            }
            &nbsp;to sign in or to request access to Extranet.
            <FormCheck
                style={{ fontSize: 'small', marginTop: '5px' }}
                checked={termsApproved}
                onChange={areTermsApproved}
                type="checkbox"
                label={<label>
                    I approve the&nbsp;
                    <a style={{ color: 'black' }} key='terms' href={'/terms-and-conditions'}>Terms</a>
                    &nbsp;and the <a style={{ color: 'black' }} key='privacy' href={'/privacy'}>Privacy Statement</a></label>}
            />
            {
                !termsApproved &&
                <Col md={8} lg={6} style={{fontSize:'small'}}>
                    <Alert variant='secondary'>You must approve the terms and conditions in order to log in or sign up</Alert>
                </Col>
            }
        </Container>
    );
}

export default LoggedOut;