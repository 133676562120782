import React, { Component } from 'react';

function Test() {

    async function Click() {
        console.log("click");
        console.log(window.location.origin);
        let joo = (await fetch(`${window.location.origin}/api/Ping`));
        console.log(joo);
    }

    return (
        <div>
            <button onClick={() => Click()}>Clickiti click</button>
        </div>
    );
}

export default Test;