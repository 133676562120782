import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

class TermsAndConditions extends Component {
    render() {
        return (
            <Container className='Container'>
                <h1>Welcome to ESL Shipping Portal!</h1>
                <br />
                These terms and conditions outline the rules and regulations for the use of ESL Shipping Oy's Website, located at <a href='https://portal.eslshipping.com/'>https://portal.eslshipping.com/</a>.
                <br /><br />
                By accessing this website we assume you accept these terms and conditions. Do not continue to use ESL Shipping Portal if you do not agree to take all of the terms and conditions stated on this page.
                <br /><br />
                The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: “Client”, “You” and “Your” refers to you, the person log on this website and compliant to the Company's terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to ESL Shipping Ltd. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client's needs in respect of provision of the Company's stated services, in accordance with and subject to, prevailing law of Finland. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.
                <br /><br />
                
                <h2>Cookies</h2>
                We employ the use of cookies. By accessing ESL Shipping Portal, you agreed to use cookies in agreement with the ESL Shipping Oy's Privacy Policy.
                <br /><br />
                Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.
                <br /><br />
                
                <h2>License</h2>
                Unless otherwise stated, ESL Shipping Oy and/or its licensors own the intellectual property rights for all material on ESL Shipping Portal. All intellectual property rights are reserved. You may access this from ESL Shipping Portal for your own personal use subjected to restrictions set in these terms and conditions.
                <br /><br />
                You must not, <i>without separate agreement:</i><br />
                •	Republish material from ESL Shipping Portal<br />
                •	Sell, rent or sub-license material from ESL Shipping Portal<br />
                •	Reproduce, duplicate or copy material from ESL Shipping Portal<br />
                •	Redistribute content from ESL Shipping Portal
                <br /><br />
                This Agreement shall begin on the date hereof.
                <br /><br />

                <h2>iFrames</h2>
                Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.
                <br /><br />
                <h2>Content Liability</h2>
                We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.
                <br /><br />

                <h2>Reservation of Rights</h2>
                We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it's linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.
                <br /><br />

                <h2>Removal of links from our website</h2>
                If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.
                <br /><br />
                We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.
                <br /><br />

                <h2>User Management</h2>
                We take no responsibility of monitoring who is using the accounts created. As the creation of user accounts is always agreed with customers for a certain email address, the responsibility of maintaining proper user management falls to the company using this web service.
                <br /><br />

                <h2>Disclaimer</h2>
                We will not be liable for any loss or damage of any nature regarding any information on the website.
                <br /><br />
                <br /><br />
            </Container>
        )
    }
}

export default TermsAndConditions;
