import React, { Component } from 'react';
import { useEffect } from 'react';
import { Card } from "react-bootstrap";

function PreviewBox({ boxContent }) {

    // useEffect(() => {
    // }, []);

    return (
        <a href={boxContent.link} style={{ textDecoration: "none" }}>
            <Card className='PreviewBox'>
                <Card.Img variant="top" src={boxContent.image} />
                <Card.Body>
                    <Card.Title>{boxContent.title}</Card.Title>
                    <Card.Text>
                        {boxContent.text}
                    </Card.Text>
                </Card.Body>
            </Card>
        </a>
    );

}

export default PreviewBox;